import { ApiWorkflowJson } from '@/app/workflows/actions/interfaces';

export type OperationId = keyof ApiWorkflowJson['config'];

interface OperationDefinition {
  id: OperationId;
  name: string;
  description: string;
  optional: boolean;
  disabledWhen?: OperationId[];
  requiredWhenMissing?: OperationId[];
}

const operations: OperationDefinition[] = [
  {
    id: 'uvUnwrapping',
    name: 'UV Unwrapping',
    description: 'Unwrap UVs of an input model',
    optional: true,
    disabledWhen: ['limits', 'assetSimplification', 'targets', 'sceneManipulation'],
  },
  {
    id: 'limits',
    name: 'Limits',
    description:
      'Choose one or more limiting factors for your optimization. Limits are designed as maximum allowed factors. This can result in less limits or file size than requested due to another limit.',
    optional: true,
    disabledWhen: ['uvUnwrapping'],
    requiredWhenMissing: ['uvUnwrapping'],
  },
  {
    id: 'targets',
    name: 'Targets',
    description:
      'Smart targets do not serve any performance goal directly but rather optimize for a given visual quality metric. The result can be limited in terms of maximum face count, texture resolution or mb through the section above.',
    optional: true,
    disabledWhen: ['uvUnwrapping'],
  },
  {
    id: 'sceneManipulation',
    name: 'Scene Manipulation',
    description: '',
    optional: true,
    disabledWhen: ['uvUnwrapping'],
  },
  {
    id: 'assetSimplification',
    name: 'Asset Simplification',
    description:
      'Specifies how the asset should be simplified, including aspects such as node merging, tiling and atlasing, baking settings and more',
    optional: true,
    disabledWhen: ['uvUnwrapping'],
    requiredWhenMissing: ['uvUnwrapping'],
  },
  {
    id: 'compressionAndExport',
    name: 'Compression and Export',
    description: 'Settings for data compression and file export',
    optional: false,
  },
];

const operationIds = new Set(operations.map((o) => o.id));
export { operations, operationIds };
