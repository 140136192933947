import { JsonSchema, JsonSchema7 } from '@jsonforms/core';
import { OperationId } from '../../components/v6/operations/operations';
import { ApiWorkflowJson, AssetSimplification, Limits, SceneManipulation, UvUnwrapping } from '../interfaces';
import { GrowthbookFeature } from '@/app/contexts/GrowthbookContext';

const defaultAssetSimplification: AssetSimplification = {
  meshDecimation: {
    method: 'rebake',
  },
  topologySettings: {
    vertexMergingDistance: 0.005,
    meshDensityEqualization: 0,
    boundaryPreservationFactor: 0.5,
    preserveTopology: false,
  },
  uvAndAtlasSettings: {
    uvStretchTolerance: 0,
    atlasingFactor: 1,
  },
  materialAndTextureBaking: {
    bakingQuality: 'medium',
    bakeNormalMap: true,
    bakeAOMap: false,
    normalsShading: 60,
    alphaBlendToMask: false,
  },
};
const defaultLimits: Limits = {
  faces: {
    percentage: 50,
  },
  textures: {
    baseColor: 2048,
    emissive: 2048,
    normal: 2048,
    orm: 2048,
  },
};
const defaultSceneManipulation: SceneManipulation = {
  rotateZUp: false,
  centerModel: false,
  removeInvisibleGeometry: false,
  removeSmallFeatures: {
    removalPercentage: 0,
  },
};

export const getDefaultSettingValue = (operationId: OperationId, features: GrowthbookFeature[]) => {
  switch (operationId) {
    case 'limits': {
      return defaultLimits;
    }
    case 'sceneManipulation': {
      return defaultSceneManipulation;
    }
    case 'assetSimplification': {
      return defaultAssetSimplification;
    }
    case 'uvUnwrapping': {
      return { method: 'cube' } satisfies UvUnwrapping;
    }
    case 'compressionAndExport': {
      return getBlankWorkflow(features).config.compressionAndExport;
    }
    case 'targets': {
      return {};
    }
    default: {
      return undefined;
    }
  }
};

export const getBlankWorkflow = (features: GrowthbookFeature[]): ApiWorkflowJson => {
  const hasUvUnwrapping = features.includes('show-uvunwrap');
  // SCHEMA_VERSION (to find searching it later)
  const schema = hasUvUnwrapping ? '2.13' : '2.11';
  const uvUnwrapping = hasUvUnwrapping ? ({ method: 'cube' } satisfies UvUnwrapping) : undefined;
  const limits = hasUvUnwrapping ? undefined : defaultLimits;
  const sceneManipulation = hasUvUnwrapping ? undefined : defaultSceneManipulation;
  const assetSimplification = hasUvUnwrapping ? undefined : defaultAssetSimplification;
  return {
    name: 'Custom Workflow',
    description:
      'A preset with default settings that you can customize to your needs. Feel free to make changes to settings, name and description.',
    meta: {
      filename: {
        useId: false,
        usePresetName: false,
        useSuffix: '',
      },
    },
    config: {
      schema: schema,
      limits,
      sceneManipulation,
      assetSimplification,
      compressionAndExport: {
        fileExports: [
          {
            fileType: 'glb',
            meshCompressionMethod: 'none',
            customScaling: 1,
            textureFormat: {
              baseColor: 'auto',
              emissive: 'auto',
              normal: 'auto',
              orm: 'auto',
            },
          },
          {
            fileType: 'usdz',
            meshCompressionMethod: 'none',
            customScaling: 1,
            textureFormat: {
              baseColor: 'auto',
              emissive: 'auto',
              normal: 'auto',
              orm: 'auto',
            },
          },
          {
            fileType: 'obj',
            meshCompressionMethod: 'none',
            customScaling: 1,
            textureFormat: {
              baseColor: 'auto',
              emissive: 'auto',
              normal: 'auto',
              orm: 'auto',
            },
          },
        ],
      },
      targets: undefined,
      uvUnwrapping,
    },
  };
};

export const getBlankWorkflowV7 = () => {
  return {
    name: 'Custom Workflow',
    description:
      'A preset with default settings that you can customize to your needs. Feel free to make changes to settings, name and description.',
    meta: {
      filename: {
        useId: false,
        usePresetName: false,
        useSuffix: '',
      },
    },
    config: {
      export: [
        {
          preserveTextureFilenames: false,
          reencodeTextures: 'auto',
          format: {
            gltf: {
              geometryCompression: 'none',
              excludeTangents: true,
            },
          },
        },
      ],
    },
  };
};
