import { urlHelper } from '@/utils';

const INTERNAL_ROUTES = [
  urlHelper.workflows(),
  urlHelper.assets(),
  urlHelper.pipelines(),
  urlHelper.settings(),
  urlHelper.embeded(),
  urlHelper.published(),
  urlHelper.cli(),
  urlHelper.plugins(),
  urlHelper.webhooks(),
  urlHelper.usageStatistics(),
  urlHelper.apiTokens(),
  urlHelper.plan.choosePlan(),
  urlHelper.plan.paymentInfo(),
  urlHelper.plan.summary(),
  urlHelper.admin(),
  urlHelper.scaleWidget(),
];

export function isInternalRoute(pathname: string) {
  return INTERNAL_ROUTES.some((path) => pathname.startsWith(path));
}
