export const NO_DESCRIPTION = 'No description available.';

export const factoryWorkflowDescriptions: { [key: string]: string | { v6: string; v7: string } } = {
  'Preserve UVs and Normals':
    'Preserves scene graph, UVs, normals and only reduces mesh and texture size. Adds mesh and texture compression for minimal file size.',
  'Baking & Decimation':
    'Flattens scene graph and bakes new texture maps from input textures and geometry. This creates new atlases, new combined normal maps, new occlusion map. In rare cases file size can be larger than the input due to additional texture maps being generated.',
  'Baking & Remeshing':
    'For models with intricate geometry details, which decimation might deteriorate, remeshing allows us to simplify the topology without including unneeded details. We do not recommend remeshing for any industrial use cases which require very exact measurements.',
  'KTX Compression':
    'Provides KTX textures without mesh decimation. KTX (Khronos Texture) is an efficient, lightweight container that reduces download time.',
  'WebP Textures':
    'Provides WebP textures without mesh decimation. Webp is more than 25% smaller than JPEG at the equivalent quality. You can use a combination of image formats for different textures.',
  'Single-Item Mobile': {
    v6: 'Optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://github.com/KhronosGroup/3DC-Asset-Creation/blob/main/asset-creation-guidelines/full-version/sec99_PublishingTargets/PublishingTargets.md">3D Commerce Publishing Guidelines v1.0</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 200k faces.',
    v7: 'Optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://github.com/KhronosGroup/3DC-Asset-Creation/blob/main/asset-creation-guidelines/full-version/sec99_PublishingTargets/PublishingTargets.md">3D Commerce Publishing Guidelines v1.0.</a> Generates UVs and bakes materials into one or more atlases, depending on transparency. Decimation target is capped at 150k faces. Suited for mobile and web display.',
  },
  'Single-Item Desktop':
    'Optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://github.com/KhronosGroup/3DC-Asset-Creation/blob/main/asset-creation-guidelines/full-version/sec99_PublishingTargets/PublishingTargets.md">3D Commerce Publishing Guidelines v1.0</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 300k faces.',
  'Lens Studio':
    'AR ready optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://lensstudio.snapchat.com/guides/submission/performance-and-optimization/">official guidelines</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 100k faces.',
  'Reality Composer':
    'AR ready usdz format and <a target="_blank" rel="noopener noreferrer" href="https://graphics.pixar.com/usd/release/wp_usdpreviewsurface.html">UsdPreviewSurface</a> material. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 100k faces.',
  'Apple Vision Pro':
    'A preset for the Apple Vision Pro to comply with the requirements of the headset and Apple Quicklook',
  'Office 3D':
    'Optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://support.microsoft.com/en-us/office/3d-content-guidelines-for-microsoft-03a7b493-d549-4f1a-9735-f2457adf6261">3D Content Guidelines for Microsoft</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 100k faces.',
  'Spark AR':
    'AR ready optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://sparkar.facebook.com/ar-studio/learn/articles/fundamentals/technical-guidelines/">official guidelines</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 100k faces.',
  'Game Engine': {
    v6: 'Game Engine import ready Optimization settings; adhering to the guidelines from <a target="_blank" rel="noopener noreferrer" href="https://docs.unrealengine.com/5.0/en-US/working-with-content-in-unreal-engine/">Unreal Engine</a> and <a target="_blank" rel="noopener noreferrer" href="https://docs.unity3d.com/Manual/ModelingOptimizedCharacters.html">Unity 3D</a>. This preset combines Uvs and baked materials into one or multiple atlases.',
    v7: 'Game Engine import ready Optimization settings; adhering to the guidelines from <a target="_blank" rel="noopener noreferrer" href="https://docs.unrealengine.com/5.0/en-US/working-with-content-in-unreal-engine/">Unreal Engine</a> and <a target="_blank" rel="noopener noreferrer" href="https://docs.unity3d.com/Manual/ModelingOptimizedCharacters.html">Unity 3D</a>. This preset combines Uvs and baked materials into one or multiple atlases. Face target is 50.000 faces.',
  },
  'Banner Ad':
    'Optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://github.com/KhronosGroup/3DC-Asset-Creation/blob/main/asset-creation-guidelines/full-version/sec99_PublishingTargets/PublishingTargets.md">3D Commerce Publishing Guidelines v1.0</a>. Aggregates UVs and bakes materials into a single atlas. Decimation targets (low-high) are below 50k faces.',
  'Adobe Aero':
    'AR ready optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://helpx.adobe.com/aero/using/prepare-3d-content-aero.html">official guidelines</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 150k faces.',
  'Metaverse Platform':
    'Metaverse Platform import ready Optimization settings; based on the guidelines from Metaverse platforms such as <a target="_blank" rel="noopener noreferrer" href="https://create.roblox.com/docs/building-and-visuals/external-modeling/mesh-requirements">Roblox</a> and <a target="_blank" rel="noopener noreferrer" href="https://docs.decentraland.org/development-guide/scene-limitations/">Decentraland</a>. This preset combines Uvs and baked materials into one atlas.',
  'Planning Tool':
    'Optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://github.com/KhronosGroup/3DC-Asset-Creation/blob/main/asset-creation-guidelines/full-version/sec99_PublishingTargets/PublishingTargets.md">3D Commerce Publishing Guidelines v1.0</a>. Aggregates UVs and bakes materials into a single atlas. Decimation targets (low-high) are below 50k faces.',
  Hololens:
    'MR ready optimization according to the <a target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/windows/mixed-reality/distribute/creating-3d-models-for-use-in-the-windows-mixed-reality-home">Windows MR Guidelines</a>. Aggregates UVs and bakes materials into one or more atlases, depending on transparency. Decimation targets (low-high) are below 100k faces.',
  'KTX Texture Compression':
    'Provides KTX textures without mesh optimization. KTX (Khronos Texture) is an efficient, lightweight container that is especially useful for saving VRAM as it does not need to be decompressed on the GPU for rendering.',
  'Simple LOD':
    'A single simple Level of Detail. Preserves scene graph, Materials, UVs and normals. Only reduces mesh and texture size.',
  'Simple LOD Compression':
    'A single simple Level of Detail. Preserves scene graph, UVs, normals and only reduces mesh and texture size. Adds mesh and texture compression for minimal file size.',
  'WebP Texture Compression':
    'Provides WebP textures without mesh optimization. Webp is more than 25% smaller than JPEG at the equivalent quality. You can use a combination of image formats for different textures.',
};
