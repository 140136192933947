export const APP_ORDER = [
  {
    id: 3004,
    name: 'Game Engine Mid Res',
  },
  {
    id: 2022,
    name: 'Planning Tool Mid Res',
  },
  {
    id: 3007,
    name: 'Metaverse Engine Mid Res',
  },
  {
    id: 2004,
    name: 'Lens Studio Mid Res',
  },
  {
    id: 2007,
    name: 'Adobe Aero Mid Res',
  },
  {
    id: 2002,
    name: 'Reality Composer Mid Res',
  },

  {
    id: 2001,
    name: 'Spark AR Mid Res',
  },
  {
    id: 2019,
    name: 'Banner Ad Mid Res',
  },

  {
    id: 2010,
    name: 'Office 3D Mid Res',
  },
];

export const GENERAL_ORDER = [
  {
    id: 5001,
    name: 'Preserve UVs and Normals Mid Res',
  },
  {
    id: 5004,
    name: 'Baking & Decimation Mid Res',
  },
  {
    id: 5007,
    name: 'Baking & Remeshing Mid Res',
  },
  {
    id: 5013,
    name: 'WebP Textures Mid Res',
  },
  {
    id: 5010,
    name: 'KTX Compression Mid Res',
  },
];

export const DEVICE_ORDER = [
  {
    id: 4002,
    name: 'Apple Vision Pro Mid Res',
  },
  {
    id: 2016,
    name: 'Single-Item Mobile Mid Res',
  },
  {
    id: 2025,
    name: 'Single-Item Desktop Mid Res',
  },
  {
    id: 2013,
    name: 'Hololens Mid Res',
  },
];

const createMergedOrderAndMap = (
  ...orders: Array<{ id: number; name: string }[]>
): Map<number, number> => {
  const maxLength = Math.max(...orders.map((order) => order.length));
  const idToIndexMap = new Map<number, number>();
  let index = 0;

  for (let i = 0; i < maxLength; i++) {
    for (const order of orders) {
      if (i < order.length) {
        idToIndexMap.set(order[i].id, index++);
      }
    }
  }

  return idToIndexMap;
};

export const ALL_WORKFLOWS_ORDER = createMergedOrderAndMap(
  GENERAL_ORDER,
  DEVICE_ORDER,
  APP_ORDER
);
