import { OperationId, operations } from '@/app/workflows/components/v6/operations/operations';
import { Workflow, WorkflowConfig } from '@/app/workflows/actions/interfaces';

export function getEnabledOperations(
  changedOperationId: OperationId,
  enabled: boolean,
  workflow: Workflow
): OperationId[] {
  const operation = operations.find((p) => p.id === changedOperationId);
  if (!operation) {
    throw new Error('Operation not found');
  }
  if (enabled) {
    const newEnabled =
      workflow.clientMetadata?.enabledOperations?.filter((o) => {
        const definition = operations.find((i) => i.id === o);
        const isDisabled = definition?.disabledWhen?.includes(changedOperationId) ?? false;
        return !isDisabled;
      }) ?? [];

    newEnabled.push(changedOperationId);

    for (const operation of operations) {
      const shouldBeEnabled = (operation.requiredWhenMissing ?? []).some((o) => !newEnabled.includes(o));

      if (shouldBeEnabled) {
        newEnabled.push(operation.id);
      }
    }

    return [...new Set([...newEnabled])];
  }

  const operationsToEnable = operations
    .filter((o) => o.requiredWhenMissing?.includes(changedOperationId))
    .map((o) => o.id);
  const newOperations = [
    ...new Set([
      ...(workflow.clientMetadata.enabledOperations?.filter((o) => o !== changedOperationId) ?? []),
      ...(operationsToEnable ?? []),
    ]),
  ];

  return newOperations;
}

export function getInitialEnabledOperations(config: Workflow['config']) {
  return Object.keys(config) as OperationId[];
}
