import { Text, Anchor, Paper } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import Link from 'next/link';
import { useEffect } from 'react';
import { showSuccessNotification } from './shared/notifications';

export enum BannerType {
  OPEN_BETA = 'OPEN_BETA',
  DISCOUNT = 'DISCOUNT',
  BLACK_FRIDAY = 'BLACK_FRIDAY',
}

export enum BannerElementsType {
  TEXT = 'TEXT',
  LINK = 'LINK',
  CODE = 'CODE',
}

type BannerLink = {
  type: BannerElementsType.LINK;
  text: string;
  href: string;
};

type BannerBasic = {
  type: BannerElementsType.TEXT | BannerElementsType.CODE;
  text: string;
};

type BannerConfig = (BannerLink | BannerBasic)[];

const BANNER_CONFIG: Record<BannerType, BannerConfig> = {
  [BannerType.OPEN_BETA]: [
    { type: BannerElementsType.TEXT, text: '🎉 Enjoy our starter plan for free until August 1st! ' },
    { type: BannerElementsType.LINK, text: 'Upgrade now', href: '/choose_plan' },
    { type: BannerElementsType.TEXT, text: ' to continue enjoying premium features without interruption.' },
  ],

  [BannerType.DISCOUNT]: [
    { type: BannerElementsType.TEXT, text: '💸 Save 25% on ' },
    { type: BannerElementsType.LINK, text: 'new plans', href: '/choose_plan' },
    { type: BannerElementsType.TEXT, text: ' with code ' },
    { type: BannerElementsType.CODE, text: 'RAPIDLAUNCH25' },
    { type: BannerElementsType.TEXT, text: '. Offer ends August 1st!' },
  ],
  [BannerType.BLACK_FRIDAY]: [
    { type: BannerElementsType.TEXT, text: '💸 Black Friday Sale! Get 25% OFF with code ' },
    { type: BannerElementsType.CODE, text: 'RAPIDSALE24' },
    { type: BannerElementsType.TEXT, text: ' – ' },
    { type: BannerElementsType.LINK, text: 'Upgrade now!', href: '/choose_plan' },
  ],
};

interface BannerProps {
  type: BannerType;
}

const Banner: React.FC<BannerProps> = ({ type }) => {
  const elements = BANNER_CONFIG[type];

  return (
    <Paper
      style={{
        padding: '10px 0',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Text size='md' fw={500}>
        {elements.map((element) => {
          if (element.type === BannerElementsType.TEXT) {
            return element.text;
          }

          if (element.type === BannerElementsType.CODE) {
            return <Code key={element.text}>{element.text}</Code>;
          }

          if (element.type === BannerElementsType.LINK) {
            return (
              <Anchor component={Link} key={element.text} href={element.href}>
                {element.text}
              </Anchor>
            );
          }
        })}
      </Text>
    </Paper>
  );
};

export default Banner;

const Code = ({ children }: { children: string }) => {
  const { copy, copied } = useClipboard();
  useEffect(() => {
    if (copied) {
      showSuccessNotification({ message: 'Code copied to clipboard!' });
    }
  }, [copied]);

  return (
    <Text fw={700} display='inline' style={{ cursor: 'pointer' }} onClick={() => copy(children)}>
      {children}
    </Text>
  );
};
