import {
  ALL_WORKFLOWS_ORDER,
  APP_ORDER,
  DEVICE_ORDER,
  GENERAL_ORDER,
} from '../constants/order';

const sortByOrder = (orderArray: { id: number }[]) => {
  return (a: { id: number }, b: { id: number }) => {
    const aIndex = orderArray.findIndex((item) => item.id === a.id);
    const bIndex = orderArray.findIndex((item) => item.id === b.id);
    return aIndex - bIndex;
  };
};

export const byApplicationOrder = sortByOrder(APP_ORDER);

export const byGeneralOrder = sortByOrder(GENERAL_ORDER);

export const byDeviceOrder = sortByOrder(DEVICE_ORDER);

export const byAllWorkflowsOrder = (
  a: { id: number },
  b: { id: number }
): number => {
  const indexA = ALL_WORKFLOWS_ORDER.get(a.id) ?? Number.MAX_SAFE_INTEGER;
  const indexB = ALL_WORKFLOWS_ORDER.get(b.id) ?? Number.MAX_SAFE_INTEGER;
  return indexA - indexB;
};
