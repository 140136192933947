import { get } from 'lodash';

export const getFileFormatsV7 = (config: Record<string, any>) => {
  try {
    const formats = ((config.export ?? []) as Record<string, any>[]).flatMap((value) => Object.keys(value.format));

    const uniqueFormats = [...new Set(formats)];
    if (uniqueFormats.length === 0) {
      return ['-'];
    }
    return [...new Set(formats)];
  } catch (error) {
    console.error('Error getting file formats', error);
    return ['-'];
  }
};

export const getMeshResolutionV7 = (config: Record<string, any>) => {
  try {
    const facesPath = [
      'optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target.faces',
      'optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.target.faces',
    ];

    for (const path of facesPath) {
      const property = get(config, path);
      if (!property) {
        continue;
      }
      if (property.percentage) {
        return `${property.percentage}%`;
      }
      if (property.value) {
        return `${property.value}`;
      }
    }

    return '-';
  } catch (error) {
    console.error('Error getting mesh resolution', error);
    return '-';
  }
};

export const getTextureResolutionsV7 = (config: Record<string, any>) => {
  const paths = [
    'format.fbx.3dsMaxPhysicalMaterial.maxTextureResolution.default',
    'format.obj.mtl.maxTextureResolution.default',
    'format.gltf.pbrMaterial.maxTextureResolution.default',
    'format.glb.pbrMaterial.maxTextureResolution.default',
    'format.vrm.pbrMaterial.maxTextureResolution.default',
    'format.usd.usdPreviewSurface.maxTextureResolution.default',
    'format.usda.adobeStandardMaterial.maxTextureResolution.default',
    'format.usdc.adobeStandardMaterial.maxTextureResolution.default',
    'format.usdz.adobeStandardMaterial.maxTextureResolution.default',
    'format.ply.defaultMaterial.maxTextureResolution.default',
  ];
  const list = [];
  for (const exportItem of config.export) {
    for (const path of paths) {
      const property = get(exportItem, path);
      if (property) {
        list.push(property);
      }
    }
  }

  if (list.length > 0) {
    const uniqueList = [...new Set(list)];
    return uniqueList;
  }

  return ['-'];
};
